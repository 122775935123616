<template>
  <svg viewBox="0 0 297.357 70.464" :width="width">
    <g transform="translate(0 0)">
      <g clip-path="url(#clip-path)">
        <path
          d="M36.017,0a5.407,5.407,0,0,0,.626.094A34.364,34.364,0,0,1,48.05,2.458,34.863,34.863,0,0,1,55.344,6.35v6.042c-8.473-6.9-17.957-9.2-28.5-6.3a29.26,29.26,0,0,0-16.74,12.1A30.25,30.25,0,0,0,13.99,56.935a29.637,29.637,0,0,0,20.429,8.636,30.085,30.085,0,0,0,20.842-7.462,43.1,43.1,0,0,1,.084,6c-.068.054-.153.131-.246.194a34.969,34.969,0,0,1-15.2,5.81,33.491,33.491,0,0,1-6.1.293,34.87,34.87,0,0,1-20.773-7.864A34.509,34.509,0,0,1,.632,41.792a32.385,32.385,0,0,1-.609-5.077A35.2,35.2,0,0,1,27.188.985a33.273,33.273,0,0,1,6.687-.89A4.983,4.983,0,0,0,34.5,0Z"
          fill="#fff"
        />
        <path
          d="M297.357,70.464c-.23-.024-.46-.067-.69-.068-1.263-.007-2.526,0-3.789,0h-.641q-10.09-27.684-20.174-55.347l-.137,0Q261.84,42.718,251.745,70.411H246.6L271.983.763c.13.332.214.533.288.737Q277,14.481,281.733,27.463q7.65,20.99,15.3,41.978c.1.267.214.527.322.79Z"
          fill="#fff"
        />
        <path
          d="M184.558,1.792h51.9a27.816,27.816,0,0,1,.019,4.766c-1.274.032-2.569.01-3.864.014-1.321,0-2.643,0-3.965,0H189.385v27.1c3.386.018,6.74.006,10.094.008q5.073,0,10.145,0h10.111v4.816H189.39V65.588c2.632,0,5.248,0,7.863,0H236.52v4.737c-.41.126-51.084.172-51.915.05-.117-.419-.171-67.368-.047-68.584"
          fill="#fff"
        />
        <path
          d="M145.723,69.78,115.761,1.572h5.281L145.633,57.55a.483.483,0,0,0,.093.016.1.1,0,0,0,.052-.024.325.325,0,0,0,.073-.089q12.28-27.945,24.563-55.9h5.28L145.723,69.78"
          fill="#fff"
        />
        <path
          d="M90.9.924q12.655,34.727,25.329,69.5h-5.146Q100.991,42.748,90.914,15.1a.59.59,0,0,0-.123,0,.081.081,0,0,0-.044.035,1.3,1.3,0,0,0-.073.156Q80.631,42.809,70.591,70.328a.646.646,0,0,1-.061.08h-5.1Q78.109,35.625,90.758.92l.138,0"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 300,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  max-width: 70vw;
}
</style>
